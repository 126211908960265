@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@700&display=swap');

* { margin: 0; padding: 0; }

:root {
    --url-color: #ED1C24;
    --text-color: #3A3A3A;
    --text-inactive-color: #CCCCCC;
    --separator-color: #CCC;
    --bg-color-main: #FFF;
    --bg-color-secondary: #E7F8FF;
    --bg-color-third: #E5E5E5;

    --logo-text-size: 1.6rem;
    --heading-text-size: 1.4rem;
    --section-heading-text-size: 1.2rem;
    --main-text-size: .9rem;
    --note-text-size: .8rem;

    --main-padding: 30px;
    --half-padding: 15px;
    --border-radius: 10px;
}

body {
    background-color: var(--bg-color-main);
    font-family: 'Montserrat', SansSerif, serif;
    color: var(--text-color);
    line-height: 1.2;
}

h1, h2, h3 { font-weight: 700; }

h1 { font-size: var(--logo-text-size); }
h2 { font-size: var(--heading-text-size); }
h3 { font-size: var(--section-heading-text-size); }

p { font-size: var(--main-text-size); }

a {
    color: var(--url-color);
    text-decoration: none;
}

div, p {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

/* UTILS */

.separator {
    height: 1px;
    border-bottom: var(--separator-color) 1px solid;
}

.shadow { box-shadow: 0 0 10px rgba(0,0,0,0.05); }

.disabled { cursor: default; }

.disabledClick {
    cursor: default;
    pointer-events: none;
    opacity: .3;
}

.enabledClick {
    cursor: pointer;
    pointer-events: auto;
    opacity: 1;
}

/* LAYOUT */

.wrapper {
    display: flex;
    justify-content: center;
}

.container {
    width: 100%;
    height: 100vh;
}

/* CONTROLS */

.controlsHeaderContainer {
    position: absolute;
    background-color: #FFF;
    left: 0;
    top: 0;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
}

.cardsContainer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 10px 0;
}

.cardsContainer-box {
    width: 100%;
    overflow-x: scroll;
}

.card-image-container {
    width: 100%;
    height: 80px;
}

.card-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
}

.card-activity-container {
    padding: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.05);
    display: flex;
    margin-bottom: 20px;
}

/*
/ Search Preview
 */

.search-preview-container {
    overflow: hidden;
    position: fixed;
    width: 100%;
    left: 0;
    padding-left: 10px;
}

.search-preview-content {
    overflow-x: scroll;
    display: flex;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}


.search-preview-content::-webkit-scrollbar {
    display: none;
}


.search-preview-card {
    border-radius: 4px;
    background: #FFF;
    box-shadow: 0 0 20px rgba(0,0,0,0.15);
    display: flex;
    width: 200px;
    height: 50px;
    min-width: 200px;
    overflow: hidden;
    margin-bottom: 20px;
}

.search-preview-image {
    width: 50px;
    height: 50px;
    object-fit: cover;
}

/*
/ About
 */

.about-container {
    padding: 30px;
}



/*
/ Media Queries
 */

@media (min-width: 0px) and (max-width:450px) {
    :root {
        --logo-text-size: 1.5rem;
        --heading-text-size: 1.3rem;
        --section-heading-text-size: 1.1rem;
        --main-text-size: .8rem;
        --note-text-size: .6rem;
        --main-padding: 20px;
        --half-padding: 10px;
    }
}

@media (min-width: 0px) and (max-width:330px) {
    :root {
        --logo-text-size: 1.4rem;
        --heading-text-size: 1.2rem;
        --section-heading-text-size: 1rem;
        --main-text-size: .7rem;
        --note-text-size: .6rem;
        --main-padding: 15px;
        --half-padding: 8px;
    }

}
